import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const formatDate = (day_or_string, format) => {
    if (!dayjs.isDayjs(day_or_string)) {
        day_or_string = toDay(day_or_string);
    }
    if (day_or_string.isValid()) {
        return day_or_string.format(format);
    }
    return '';
};

export const toDay = string_or_day => {
    if (dayjs.isDayjs(string_or_day)) {
        return string_or_day;
    }
    if (typeof string_or_day === 'string') {
        return dayjs(string_or_day, 'YYYY/M/D');
    }
    return dayjs(string_or_day);
};

export const toRailsDate = day_or_string => formatDate(day_or_string, 'YYYY/MM/DD');

export const toCalendarDate = day_or_string => formatDate(day_or_string, 'YYYY/M/D');

export const toIsoDate = day_or_string => formatDate(day_or_string, 'YYYY-MM-DD');

export const isSame = (a, b, unit) => toDay(a).isSame(toDay(b), unit);

export const inRange = (day_or_string, range) => {
    if (!toDay(day_or_string).isValid()) {
        return false;
    }
    day_or_string = toIsoDate(day_or_string);
    const min = range && range.min && toIsoDate(range.min);
    const max = range && range.max && toIsoDate(range.max);

    return (!min || min <= day_or_string) && (!max || max >= day_or_string);
}

export const dayNames = () => ['月', '火', '水', '木', '金', '土', '日'];

export const monthNames = () =>
    new Array(12).fill(0).map((e, i) => `${i + 1}月`);

export const formatYear = year => `${year}年`;
