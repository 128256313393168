import FixVideoAspect from './fix-video-aspect';

document.addEventListener('ajax:error', (event) => {
    if (event.target.getAttribute('data-training-secret') === 'true') {
        alert(event.detail[0].error || event.detail[0] || event.detail[1])
    }
});

// 戻るボタンがhistory.backなので単純にform postするわけにはいかない
document.addEventListener('ajax:success', (event) => {
    if (event.target.getAttribute('data-training-secret') === 'true') {
        const oldContent = document.querySelector('#content')
        const newContent = event.detail[0].querySelector('#content');
        oldContent.parentElement.replaceChild(newContent, oldContent);
        FixVideoAspect.fix();
    }
});
