function fix() {
    $('.trix-x-attach-video iframe').each(function () {
        const $video = $(this);
        const $fixer = $video.parent();

        if ($fixer.hasClass('trix-x-attach-video-aspect-fixed')) {
            return;
        }
        const width = $video.outerWidth();
        const height = $video.outerHeight()
        const ratio = 100 * height / width;
        $fixer.find('.trix-x-attach-video-height-fixer').css({paddingTop: `${ratio}%`});
        $fixer.css({maxWidth: `${width}px`}).addClass('trix-x-attach-video-aspect-fixed');
    });
}

export default {fix};
