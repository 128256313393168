<template>
  <div id="training-category-picker" class="input-group category-picker">
    <select :name="category.name" :placeholder="category.placeholder" :id="category.id"
            class="custom-select" :class="{'is-invalid': category.invalid}"
            v-model="category.value" @change="handleCategoryChange">
      <option value=""></option>
      <option v-for="option in category.options" :value="String(option.id)">
        {{ option.label }}
      </option>
    </select>
    <select :name="subCategory.name" :placeholder="subCategory.placeholder" :id="subCategory.id"
            class="custom-select" :class="{'is-invalid': subCategory.invalid}"
            v-model="subCategory.value" @change="updateCache">
      <option value=""></option>
      <option v-for="option in subCategories" :value="String(option.id)">
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
import VueTurboCache from '../lib/vue-turbo-cache';

export default {
  computed: {
    subCategories() {
      return this.subCategory.options.filter(e => String(e.parent_id) === this.category.value);
    }
  },
  data() {
    return {
      category: {},
      subCategory: {}
    }
  },
  methods: {
    handleCategoryChange() {
      this.subCategory.value = '';
      this.updateCache();
    },
    storeCache() {
      const fragment = VueTurboCache.fetch(this.$root);

      const category = fragment.getElementById(this.category.id);
      const subCategory = fragment.getElementById(this.subCategory.id);
      this.$turbolinksCache = {fragment, category, subCategory};
    },
    updateCache() {
      const {category, subCategory} = this.$turbolinksCache;

      Array.from(category.querySelectorAll('option')).forEach(e => e.removeAttribute('selected'));
      category.querySelector(`option[value="${this.category.value}"]`).setAttribute('selected', 'selected');

      Array.from(subCategory.querySelectorAll('option')).forEach(e => e.removeAttribute('selected'));
      subCategory.querySelector(`option[value="${this.subCategory.value}"]`).setAttribute('selected', 'selected');

      VueTurboCache.store(this.$root, this.$turbolinksCache.fragment);
    },
  },
  beforeMount() {
    [this.category, this.subCategory] = Array.from(this.$root.$el.querySelectorAll('select'))
        .map(e => ({
          name: e.getAttribute('name'),
          placeholder: e.getAttribute('placeholder'),
          value: e.value,
          id: e.getAttribute('id'),
          options: JSON.parse(e.dataset.options),
          invalid: e.classList.contains('is-invalid')
        }));
    this.storeCache();
  }
}
</script>
