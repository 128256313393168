import Axios from 'axios';

// タイムアウト対応
Axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401 && error.response.data.timeout) {
            alert("タイムアウトしました。");
        }
        return Promise.reject(error);
    }
);

// CSFR token 追加
Axios.interceptors.request.use(
    config => {
        if (config.method.match(/^(post|put|patch|delete)$/i)) {
            config.headers['X-CSRF-Token'] = $('meta[name="csrf-token"]').attr('content');
        }
        config.headers['X-Requested-With'] = 'XMLHttpRequest'
        return config;
    },
    error => Promise.reject(error)
);
