// millca (arsoa-suite) と mocopa (arsoa-mocopa) で共通

function padZero(event) {
    if (event.target.value) {
        event.target.value = event.target.value.padStart(10, '0')
    }
}

function stop() {
    for (let el of document.querySelectorAll('input[data-pad-zero]')) {
        el.removeEventListener('change', padZero);
    }
}

function start() {
    for (let el of document.querySelectorAll('input[data-pad-zero]')) {
        el.addEventListener('change', padZero);
    }
}

export default {start, stop};
