<template>
  <DatePicker :name="name" :placeholder="placeholder"
              :initial-value="value" :input-id="inputId"
              :invalid="invalid" :required="required"
              @choseDay="updateCache"></DatePicker>
</template>

<script>
import VueTurboCache from '../lib/vue-turbo-cache';
import DatePicker from '../components/date-picker';
import {toRailsDate} from '../lib/helpers'

export default {
  components: {DatePicker},
  data() {
    return {
      name: '',
      inputId: '',
      placeholder: '',
      value: '',
      required: false,
      invalid: false,
    }
  },
  methods: {
    storeCache() {
      const fragment = VueTurboCache.fetch(this.$root);
      const input = fragment.getElementById(this.inputId);

      this.$turbolinksCache = {fragment, input};
    },
    updateCache(date) {
      const {input} = this.$turbolinksCache;
      input.setAttribute('value', toRailsDate(date));

      VueTurboCache.store(this.$root, this.$turbolinksCache.fragment);
    }
  },
  beforeMount() {
    this.name = this.$root.$el.getAttribute('name');
    this.placeholder = this.$root.$el.getAttribute('placeholder');
    this.value = this.$root.$el.value;
    this.inputId = this.$root.$el.getAttribute('id');
    this.required = this.$root.$el.required;
    this.invalid = this.$root.$el.classList.contains('is-invalid');

    this.storeCache();
  }
}
</script>
