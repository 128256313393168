<template>
  <div id="input-active-storage" :class="{'is-invalid': invalid}">
    <div class="drop-area">
      <div class="drop-message" v-if="image == null">
        <strong>{{ messages.dropImageHere }}</strong>
        {{ messages.orClickToChoose }}
      </div>
      <img :src="image" v-if="image != null"/>
      <input type="hidden" :value="signedId" :name="name" v-if="signedId != null"/>
      <div class="direct-upload-progress-bar" v-if="uploading" :style="{width: `${100 - uploadingProgress}%`}"></div>
      <input :accept="accept" title="" type="file" :name="name" :id="inputId" @change="handleFileChose"
             :disabled="uploading">
    </div>
    <button type="button" class="detach-file btn btn-danger" @click="destroyFile" v-if="image != null"
            :disabled="uploading">
      <i class="fa fa-trash-o"></i> {{ messages.destroy }}
    </button>
  </div>
</template>

<script>
import {DirectUpload} from '@rails/activestorage';
import {UPLOAD_START_EVENT, UPLOAD_COMPLETE_EVENT} from '../lib/active-storage-const';

export default {
  data() {
    return {
      messages: {
        uploadError: 'ファイルアップロードに失敗しました',
        mustBeImage: '画像ファイルを選択してください',
        dropImageHere: 'ここに画像をドロップ',
        orClickToChoose: 'またはクリックして画像を選択',
        destroy: '削除'
      },
      signedId: null,
      signedIdWas: null,
      image: null,
      imageWas: null,
      invalid: false,
      name: null,
      inputId: null,
      accept: 'image/*',
      directUploadUrl: null,
      uploading: false,
      uploadingProgress: 100,
      xhr: null
    }
  },
  methods: {
    handleFileChose(event) {
      const file = (event.target.files || event.dataTransfer)[0]

      if (!file.type.startsWith('image/')) {
        alert(this.messages.mustBeImage);
        event.target.value = null;
        return;
      }
      this.image = URL.createObjectURL(file);
      this.signedId = null;
      this.directUpload(file, event.target);
      event.target.value = null;
    },
    directUpload(file, input) {
      const $input = $(input);
      $input.trigger(UPLOAD_START_EVENT);
      this.uploading = true;
      this.uploadingProgress = 0;

      new DirectUpload(file, this.directUploadUrl, this).create((error, blob) => {
        $input.trigger(UPLOAD_COMPLETE_EVENT);
        this.xhr = null;

        if (error) {
          alert(this.messages.uploadError);

          this.$nextTick(() => {
            this.image = this.imageWas;
            this.signedId = this.signedIdWas;
            this.uploading = false;
            this.uploadingProgress = 100;
          });
        } else {
          this.$nextTick(() => {
            this.signedId = blob.signed_id;
            this.imageWas = this.image;
            this.signedIdWas = this.signedId;
            this.uploading = false;
            this.uploadingProgress = 100;
          });
        }
      })
    },
    destroyFile() {
      this.image = null;
      this.signedId = '';
      this.imageWas = null;
      this.signedIdWas = '';
    },
    directUploadWillCreateBlobWithXHR(xhr) {
      this.xhr = xhr;
    },
    directUploadWillStoreFileWithXHR(xhr) {
      this.xhr = xhr;
      xhr.upload.addEventListener('progress', event => this.directUploadDidProgress(event))
    },
    directUploadDidProgress(event) {
      const {total, loaded} = event;

      if (total == null || loaded == null) {
        return;
      }

      this.$nextTick(() => this.uploadingProgress = Math.floor(100 * loaded / total));
    }
  },
  beforeMount() {
    const $root = this.$root.$el;
    this.invalid = $root.classList.contains('is-invalid');

    const data = $root.dataset;
    Object.keys(this.messages).reduce((a, e) => Object.assign(a, {[e]: data[e] || a[e]}), this.messages);

    const $signedId = $root.querySelector('input[type="hidden"]');
    this.signedId = $signedId ? $signedId.value : null;
    this.signedIdWas = this.signedId;

    const $image = $root.querySelector('img');
    this.image = $image ? $image.getAttribute('src') : null;
    this.imageWas = this.image;

    const $file = $root.querySelector('input[type="file"]');
    this.name = $file.getAttribute('name');
    this.inputId = $file.getAttribute('id');
    this.accept = $file.getAttribute('accept');
    this.directUploadUrl = $file.dataset.directUploadUrl;
  },
  beforeDestroy() {
    this.xhr && this.xhr.abort();
  }
}
</script>
