// from Bootstrap v4.5.0
const BS_TRANSITION_END = 'bsTransitionEnd';

// from Bootstrap v4.5.0
function getTransitionDurationFromElement($element) {
    if (!$element) {
        return 0;
    }

    let transitionDuration = $element.css('transition-duration');
    let transitionDelay = $element.css('transition-delay');
    const floatTransitionDuration = parseFloat(transitionDuration);
    const floatTransitionDelay = parseFloat(transitionDelay);

    if (!floatTransitionDuration && !floatTransitionDelay) {
        return 0;
    }

    transitionDuration = transitionDuration.split(',')[0];
    transitionDelay = transitionDelay.split(',')[0];
    return (parseFloat(transitionDuration) + parseFloat(transitionDelay)) * 1000;
}

function nextTick() {
    return new Promise(resolve => setTimeout(resolve, 10));
}

function eventFired($element, event) {
    return new Promise(resolve => $element.one(BS_TRANSITION_END, resolve));
}

async function enter($target, className) {
    const duration = getTransitionDurationFromElement($target);

    $target.data('transitioning', true);
    $target.addClass(`${className} ${className}-enter`);
    await nextTick();

    $target.addClass(`${className}-enter-active`);
    $target.emulateTransitionEnd(duration);
    await eventFired($target, BS_TRANSITION_END);

    $target.removeClass(`${className}-enter-active ${className}-enter`);
    $target.data('transitioning', false);
}

async function leave($target, className) {
    const duration = getTransitionDurationFromElement($target);

    $target.data('transitioning', true);
    $target.addClass(`${className}-leave`);
    await nextTick();

    $target.addClass(`${className}-leave-active`);
    $target.emulateTransitionEnd(duration);
    await eventFired($target, BS_TRANSITION_END);

    $target.removeClass(`${className}-leave-active ${className}-leave ${className}`);
    $target.data('transitioning', false);
}

$(document).on('click', '[data-transition]', function (event) {
    const $this = $(this);
    const $target = $($this.data('target'));
    const className = $this.data('transition');
    const only = $this.data('only');

    if ($target.data('transitioning')) {
        return;
    }

    if ($target.hasClass(className) && only !== 'enter') {
        leave($target, className);
    }
    if (!$target.hasClass(className) && only !== 'leave') {
        enter($target, className);
    }
});
