<template>
  <div id="category-picker" :class="{'input-group': hasSubCategory, 'category-picker': hasSubCategory, 'w-50': !hasSubCategory}">
    <select :name="category.name" :placeholder="category.placeholder" :id="category.id"
            class="custom-select" :class="{'is-invalid': category.invalid}"
            v-model="category.value" @change="updateCache">
      <option v-for="option in category.options" :value="option.value">
        {{ option.text }}
      </option>
    </select>
    <select :name="tools.name" :placeholder="tools.placeholder" :id="tools.id"
            class="custom-select" :class="{'d-none': !isTools, 'is-invalid': tools.invalid}"
            :disabled="!isTools" v-model="tools.value" @change="updateCache">
      <option v-for="option in tools.options" :value="option.value">
        {{ option.text }}
      </option>
    </select>
    <select :name="arsoaLife.name" :placeholder="arsoaLife.placeholder" :id="arsoaLife.id"
            class="custom-select" :class="{'d-none': !isArsoaLife, 'is-invalid': arsoaLife.invalid}"
            :disabled="!isArsoaLife" v-model="arsoaLife.value" @change="updateCache">
      <option v-for="option in arsoaLife.options" :value="option.value">
        {{ option.text }}
      </option>
    </select>
  </div>
</template>

<script>
import VueTurboCache from '../lib/vue-turbo-cache';

export default {
  computed: {
    isTools() {
      return this.category.value === 'tools';
    },
    isArsoaLife() {
      return this.category.value === 'arsoa_life';
    },
    hasSubCategory() {
      return this.isTools || this.isArsoaLife;
    }
  },
  data() {
    return {
      category: {},
      tools: {},
      arsoaLife: {}
    }
  },
  methods: {
    storeCache() {
      const fragment = VueTurboCache.fetch(this.$root);

      const inputGroup = fragment.querySelector('#category-picker');
      const category = fragment.getElementById(this.category.id);
      const tools = fragment.getElementById(this.tools.id);
      const arsoaLife = fragment.getElementById(this.arsoaLife.id);
      this.$turbolinksCache = {fragment, inputGroup, category, tools, arsoaLife};
    },
    updateCache() {
      const {inputGroup, category, tools, arsoaLife} = this.$turbolinksCache;

      inputGroup.classList.toggle('input-group', this.hasSubCategory);
      inputGroup.classList.toggle('category-picker', this.hasSubCategory);
      inputGroup.classList.toggle('w-50', !this.hasSubCategory);

      Array.from(category.querySelectorAll('option')).forEach(e => e.removeAttribute('selected'));
      category.querySelector(`option[value="${this.category.value}"]`).setAttribute('selected', 'selected');

      this.updateSubCategoryCache(tools, this.tools.value, this.isTools);
      this.updateSubCategoryCache(arsoaLife, this.arsoaLife.value, this.isArsoaLife);

      VueTurboCache.store(this.$root, this.$turbolinksCache.fragment);
    },
    updateSubCategoryCache(subCategory, currentValue, visible) {
      Array.from(subCategory.querySelectorAll('option')).forEach(e => e.removeAttribute('selected'));
      subCategory.querySelector(`option[value="${currentValue}"]`).setAttribute('selected', 'selected');
      if (visible) {
        subCategory.removeAttribute('disabled');
      } else {
        subCategory.setAttribute('disabled', 'disabled');
      }
      subCategory.classList.toggle('d-none', !visible);
    },
  },
  beforeMount() {
    [this.category, this.tools, this.arsoaLife] = Array.from(this.$root.$el.querySelectorAll('select'))
        .map(e => ({
          name: e.getAttribute('name'),
          placeholder: e.getAttribute('placeholder'),
          value: e.value,
          id: e.getAttribute('id'),
          options: Array.from(e.querySelectorAll('option')).map(option => ({
            value: option.getAttribute('value'),
            text: option.textContent
          })),
          invalid: e.classList.contains('is-invalid')
        }));
    this.storeCache();
  }
}
</script>
