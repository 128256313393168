<template>
  <div :class="{'vfc-align-right': alignRight}">
    <FunctionalCalendar v-model="calendarData"
                        date-format="yyyy/mm/dd"
                        :focusable="false"
                        :new-current-date="calendarConfig.newCurrentDate"
                        :year-format-func="calendarConfig.yearFormatFunc"
                        :day-names="calendarConfig.dayNames"
                        :month-names="calendarConfig.monthNames"
                        :is-year-first="true"
                        :sunday-start="true"
                        :is-date-picker="true"
                        :is-modal="true"
                        :is-auto-closeable="true"
                        :is-typeable="true"
                        :limits="limits"
                        @choseDay="handleCalendarChose"
                        @opened="handleCalendarOpen"
                        @closed="handleCalendarClose"
                        ref="calendar">
      <template v-slot:datePickerInput>
        <input class="form-control"
               :class="{'is-invalid': invalid}"
               type="text"
               autocomplete="off"
               @input="handleInput"
               @change="handleInputApply"
               :id="inputId"
               :value="value"
               :name="name"
               :placeholder="placeholder"/>
      </template>
    </FunctionalCalendar>
  </div>
</template>

<script>
import {FunctionalCalendar} from 'vue-functional-calendar';
import {toDay, toRailsDate, toCalendarDate, isSame, inRange, dayNames, monthNames, formatYear} from '../lib/helpers'

export default {
  components: {
    FunctionalCalendar
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    inputId: {
      type: String,
      default: ''
    },
    invalid: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: String,
      default: ''
    },
    limits: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      calendarConfig: {
        yearFormatFunc: formatYear,
        newCurrentDate: new Date(),
        dayNames: dayNames(),
        monthNames: monthNames()
      },
      calendarData: {
        currentDate: toDay(new Date()).startOf('month').toISOString(),
        selectedDate: '',
        selectedDateTime: false,
        selectedHour: '00',
        selectedMinute: '00',
        selectedDatesItem: '',
        selectedDates: [],
        dateRange: {start: '', end: ''},
        multipleDateRange: []
      },
      value: '',
      alignRight: false
    }
  },
  methods: {
    handleCalendarOpen() {
      const calendarRect = this.$refs.calendar.$refs.mainContainer.getBoundingClientRect();
      const windowLeft = window.pageXOffset + document.documentElement.clientWidth;
      this.alignRight = calendarRect.right > windowLeft;
    },
    handleCalendarClose() {
      this.alignRight = false;
    },
    chooseDate(day) {
      this.calendarData.selectedDate = toCalendarDate(day);

      if (!isSame(day, this.calendarData.currentDate, 'month')) {
        this.$refs.calendar.ChooseDate(this.calendarData.selectedDate);
      }
    },
    handleInput(event) {
      this.value = event.target.value;
      const value = event.target.value.trim();
      const day = toDay(value);

      if (inRange(day, this.limits)) {
        this.chooseDate(day);
      }
    },
    handleInputApply(event) {
      const value = event.target.value.trim();
      const day = toDay(value);

      if (inRange(day, this.limits)) {
        this.chooseDate(day);
        this.value = toRailsDate(day);
      } else {
        this.calendarData.selectedDate = '';
        this.value = '';
      }
      this.$emit('choseDay', this.value);
    },
    handleCalendarChose(event) {
      if (event.date) {
        this.value = toRailsDate(event.date);
      } else {
        this.value = '';
      }
      this.$emit('choseDay', this.value);
    }
  },
  created() {
    const day = toDay(this.$props.initialValue);
    if (day.isValid()) {
      this.value = toRailsDate(day);
      this.calendarConfig.newCurrentDate = day.toDate();
      this.calendarData.selectedDate = toCalendarDate(day);
      this.calendarData.currentDate = day.startOf('month').toISOString();
    }
  }
}
</script>

<style>
.vfc-main-container.vfc-modal {
  z-index: 999;
}

/* bootstrap less than md (sm or less) */
@media (max-width: 767px) {
  .vfc-main-container.vfc-modal {
    width: 280px;
  }

  .vfc-top-date {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .vfc-dayNames {
    margin-bottom: 5px;
  }

  .vfc-calendar .vfc-content {
    margin: 0 15px 15px 15px;
  }

  .vfc-navigation-buttons div, .vfc-separately-navigation-buttons div {
    margin: 15px 10px;
  }
}

/* bootstrap md or more */
@media (min-width: 768px) {
  .vfc-main-container.vfc-modal {
    width: 340px;
  }

  .vfc-top-date {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.vfc-week .vfc-day:hover {
  cursor: pointer;
}

.vfc-align-right .vfc-main-container.vfc-modal {
  right: 0;
}
</style>
