import Axios from 'axios';

const selector = '[data-request-and-reload-url]';
const dataName = 'requestAndReloadUrl';
const CancelToken = Axios.CancelToken;
let source;
let scrollTop;

function cancel() {
    if (source) {
        source.cancel();
        source = null;
    }
}

function disable(disabled) {
    Array.from(document.querySelectorAll(selector)).forEach(e => {
        if (disabled) {
            e.dataset.disabledWas = e.disabled;
            e.disabled = true;
        } else {
            e.disabled = e.dataset.disabledWas === 'true';
        }
    });
}

function request(event) {
    cancel();

    const url = event.currentTarget.dataset[dataName];
    const errorMessage = event.currentTarget.dataset.errorMessage;
    source = CancelToken.source();

    disable(true);
    scrollTop = event.currentTarget.getAttribute('data-turbolinks-scroll') === 'true' ? document.scrollingElement.scrollTop : null;
    Axios.post(url, {cancelToken: source.token})
        .then(res => {
            source = null;
            Turbolinks.visit(location);
        })
        .catch(err => {
            source = null;
            if (Axios.isCancel(err)) {
                return;
            }
            disable(false);
            alert(errorMessage);
        });
}

document.addEventListener("turbolinks:load", () => {
    source = null;
    Array.from(document.querySelectorAll(selector)).forEach(e => {
        e.addEventListener('click', request);
    });
});

document.addEventListener("turbolinks:visit", () => {
    Array.from(document.querySelectorAll(selector)).forEach(e => {
        e.removeEventListener('click', request);
        cancel();
    });
});

document.addEventListener('turbolinks:load', (event) => {
    if (scrollTop != null) {
        document.scrollingElement.scrollTop = scrollTop;
        scrollTop = null;
    }
});
