<template>
  <intersect @enter="handleEnter">
    <div v-show="nextPagePath" class="text-center my-1 text-muted">
      <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw margin-bottom"></i>
    </div>
  </intersect>
</template>

<script>
import Intersect from 'vue-intersect'
import Axios from 'axios';
import VueTurboCache from '../lib/vue-turbo-cache';

const CancelToken = Axios.CancelToken;

export default {
  components: {Intersect},
  data() {
    return {
      errorMessage: '通信エラーが発生しました。リロードしてもう一度お試しください',
      source: null,
      loading: false,
      nextPagePath: null,
      elementId: null
    }
  },
  methods: {
    handleEnter() {
      if (!this.nextPagePath || this.source) {
        return;
      }
      this.source = CancelToken.source();
      const cancelToken = this.source.token
      Axios.get(this.nextPagePath, {cancelToken}).then(this.handleResponse).catch(this.handleError);
    },
    handleResponse(res) {
      this.source = null;

      const $dom = $($.parseHTML(res.data));
      this.nextPagePath = this.elementId ? $dom.find(`#${this.elementId}`).data('next-page-path') : null;
      $(this.selector).append($dom.find(this.selector).children());

      this.updateCache(this.nextPagePath);
    },
    handleError(err) {
      this.source = null;
      this.nextPagePath = null;
      if (Axios.isCancel(err)) {
        return;
      }
      alert(this.errorMessage);
    },
    storeCache() {
      const fragment = VueTurboCache.fetch(this.$root);

      const nextPage = fragment.querySelector('#next-page');
      this.$turbolinksCache = {fragment, nextPage};
    },
    updateCache(nextPagePath) {
      this.$turbolinksCache.nextPage.setAttribute('data-next-page-path', nextPagePath || '');
      VueTurboCache.store(this.$root, this.$turbolinksCache.fragment);
    },
  },
  beforeMount() {
    const dataset = this.$root.$el.dataset;
    this.nextPagePath = dataset.nextPagePath;
    this.selector = dataset.selector;
    this.errorMessage = dataset.errorMessage || this.errorMessage;
    this.elementId = this.$root.$el.getAttribute('id');

    this.storeCache();
  },
  beforeDestroy() {
    this.source && this.source.cancel();
  }
}
</script>
