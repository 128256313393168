<template>
  <div>
    <button type="button" id="content-preview" class="btn btn-link ml-2"
            :disabled="!url || source || show" @click="preview">
      {{ label }}
    </button>
    <b-modal v-model="show" size="lg" content-class="content-preview">
      <template v-slot:modal-header>
        <div v-html="header" v-if="header"></div>
        <div v-else class="content-header-placeholder">
          <div class="content-placeholder content-date-placeholder"></div>
          <div class="content-placeholder content-title-placeholder"></div>
        </div>
      </template>
      <template v-slot:default>
        <div v-html="content" v-if="content"></div>
        <div v-else class="trix-content">
          <div class="content-placeholder"></div>
          <div class="content-placeholder"></div>
          <div class="content-placeholder"></div>
          <div class="content-placeholder"></div>
        </div>
      </template>
      <template v-slot:modal-footer>
        <button type="button" class="btn btn-secondary" @click="close">{{ closeLabel }}</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {BModal} from 'bootstrap-vue';
import Axios from 'axios';
import FixVideoAspect from '../lib/fix-video-aspect';

const CancelToken = Axios.CancelToken;

export default {
  components: {BModal},
  data() {
    return {
      errorMessage: '通信エラーが発生しました。リロードしてもう一度お試しください',
      closeLabel: '閉じる',
      label: 'プレビュー',
      source: null,
      url: null,
      show: false,
      header: '',
      content: '',
    }
  },
  methods: {
    preview() {
      if (!this.url || this.source) {
        return;
      }
      this.header = '';
      this.content = '';
      this.show = true;
      this.source = CancelToken.source();
      const cancelToken = this.source.token
      const form = new FormData($(this.$root.$el).closest('form')[0]);
      form.delete('_method');
      Axios.post(this.url, form, {cancelToken}).then(this.handleResponse).catch(this.handleError);
    },
    close() {
      this.source && this.source.cancel();
      this.source = null;
      this.show = false;
    },
    handleResponse(res) {
      this.source = null;
      const $dom = $($.parseHTML(res.data));
      this.header = $dom.find('.card-title').html();
      this.content = $dom.find('.card-text').html();
      this.$nextTick(() => this.$nextTick(() => FixVideoAspect.fix()));
    },
    handleError(err) {
      this.source = null;
      if (Axios.isCancel(err)) {
        return;
      }
      alert(this.errorMessage);
    }
  },
  beforeMount() {
    const dataset = this.$root.$el.dataset;
    this.url = dataset.url;
    this.errorMessage = dataset.errorMessage || this.errorMessage;
    this.closeLabel = dataset.closeLabel || this.closeLabel;
    this.label = this.$root.$el.textContent || this.label;
  },
  beforeDestroy() {
    this.source && this.source.cancel();
  }
}
</script>
