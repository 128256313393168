// millca (arsoa-suite) と mocopa (arsoa-mocopa) で共通

const CACHE_KEYS = ['$turbolinksCachedHTML', '$cachedHTML'];

function fetch($root) {
    const fragment = document.createDocumentFragment();
    fragment.appendChild(document.createElement('div'));

    for (let key of CACHE_KEYS) {
        if (key in $root) {
            fragment.firstElementChild.innerHTML = $root[key];
        }
    }
    return fragment;
}

function store($root, fragment) {
    for (let key of CACHE_KEYS) {
        if (key in $root) {
            $root[key] = fragment.firstElementChild.innerHTML;
        }
    }
}

export default {fetch, store};
