// millca (arsoa-suite) と mocopa (arsoa-mocopa) で共通

let turbolinksScrollTop = null;

function shouldKeepScrollTop(event) {
    return event && event.target && event.target.getAttribute &&
        event.target.getAttribute('data-turbolinks-scroll') === 'true';
}

document.addEventListener('turbolinks:click', (event) => {
    if (!shouldKeepScrollTop(event)) {
        return;
    }
    turbolinksScrollTop = document.scrollingElement.scrollTop;
});

document.addEventListener('turbolinks:load', (event) => {
    if (turbolinksScrollTop != null) {
        document.scrollingElement.scrollTop = turbolinksScrollTop;
        turbolinksScrollTop = null;
    }
});
