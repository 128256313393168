// Trixカスタマイズ、文字色
// Attribute名: color、Action名: x-color

import Trix from 'trix';

// 色を追加変更するときはここを変える
const COLORS = [
  '#000000', '#3F2724', '#E75100', '#FCD735', '#33691E', '#014D40', '#1948A1', '#321B92', '#B71C1D',
  '#616161', '#5F4038', '#F57C01', '#FCEC3A', '#699E3A', '#03796B', '#2E7AFF', '#522DA9', '#D2302F',
  '#808080', '#785549', '#FD9802', '#FCEF5A', '#8BC34A', '#069689', '#2996F3', '#683BB6', '#F64337',
  '#E0E0E0', '#A18880', '#FDB74E', '#FCF177', '#AED581', '#4EB6AC', '#64B5F8', '#9576CC', '#E57373',
  '#FFFFFF', '#D8CCC9', '#FDCB80', '#FDF59D', '#C4E1A5', '#7FCBC4', '#BBDFFB', '#D1C5E9', '#FFCDD2',
];

// TrixのAttributeの追加
Trix.config.textAttributes.color = {
  inheritable: true,
  styleProperty: 'color',
  parser: (element => element.style.color || false)
};

// ツールバーの文字色ボタン
const BUTTON_HTML = `
    <button type="button" class="trix-button trix-button--icon-color trix-button-fa-icon"
      data-trix-action="x-color" data-trix-attribute="color" title="Color" tabIndex="-1">
        <i class="fa fa-font"></i>
    </button>
`.trim();

// 文字色選択ダイアログ
function colorBoxHtml() {
  return COLORS.map(c => `<div class="trix-dialog__color-box" style="background-color:${c}"></div>`).join('');
}

const DIALOG_HTML = `
    <div class="trix-dialog trix-dialog--color" data-trix-dialog="x-color">
        <div class="trix-dialog__color-fields">${colorBoxHtml()}</div>
    </div>
`.trim();

// 初期化
addEventListener('trix-initialize', event => {
    const editorElement = event.target;
    const $toolbar = $(editorElement.toolbarElement);

    if ($toolbar.find('[data-trix-action="x-color"]').length === 0) {
        $toolbar.find('.trix-button-group.trix-button-group--file-tools').append(BUTTON_HTML);
        $toolbar.find('[data-trix-dialogs]').append(DIALOG_HTML);

        initializeColorEvents(event.target);
    }
});

// ボタンとダイアログのイベント設定
function initializeColorEvents(editor) {
    let button = editor.toolbarElement.querySelector('.trix-button--icon-color');
    if(!button) { return; }
    let dialog = editor.toolbarElement.querySelector('.trix-dialog--color');
    if(!dialog) { return; }

    // ツールバーの文字色ボタン
    button.addEventListener('mousedown', event => {
        event.stopPropagation(); // ツールバーにイベントを渡さない
        if(button.hasAttribute('data-trix-active')) {
            // 選択状態のときは色を解除
            editor.editorController.toolbarDidToggleAttribute('color');
        }
        else {
            // ダイアログ表示
            editor.editorController.toolbarController.toggleDialog('x-color');
        }
    });

    button.addEventListener('keydown', event => {
        event.stopPropagation();
        if(event.keyCode == 13) { // Enter key
            event.preventDefault();
        }
        if(event.keyCode == 27) { // Escape key
            event.preventDefault();
            editor.editorController.toolbarController.hideDialog();
        }
    });

    // 文字色ダイアログの色選択
    dialog.addEventListener('click', event => {
        if(event.target.classList.contains('trix-dialog__color-box')) {
            editor.editor.activateAttribute('color', event.target.style.backgroundColor);
            editor.editorController.toolbarController.hideDialog();
        }
    });
}