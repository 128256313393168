// millca (arsoa-suite) と mocopa (arsoa-mocopa) で共通

function isEqual(a, b) {
    if (a == null && b == null) {
        return true;
    }
    if (a == null || b == null) {
        return false;
    }
    return a.toUpperCase() === b.toUpperCase();
}

function isTurbolinksForm(form) {
    return isEqual(form.nodeName, 'FORM') &&
        isEqual(form.getAttribute('method'), 'get') &&
        form.getAttribute('data-remote') === 'true' &&
        form.getAttribute('data-turbolinks-form') === 'true';
}

document.addEventListener('ajax:beforeSend', (event) => {
    if (isTurbolinksForm(event.target)) {
        Turbolinks.dispatch('turbolinks:click', {target: event.target, cancelable: false});

        Turbolinks.visit(event.detail[1].url);
        event.stopImmediatePropagation();
        event.preventDefault();

        return false;
    }
});
