<template>
  <div class="row root">
    <div class="col">
      <div class="card">
        <div class="card-header">
          {{ t.mainTag }}
        </div>
        <div class="list-group list-group-flush">
          <div v-if="initialLoading" class="list-group-item text-muted text-center">
            <i class="fa fa-circle-o-notch fa-spin fa-fw"></i>
          </div>
          <a v-for="tag in mainTags" :key="tag.id" class="list-group-item list-group-item-action cursor-pointer with-buttons"
              :class="{active: tag.id === currentRecord.id && showModal || selectedTag && tag.id === selectedTag.id}" @click="edit(tag)">
            {{ tag.name }}
            <div class="list-group-item-buttons">
              <button type="button" class="btn btn-sm btn-danger" @click="destroy($event, tag)" :disabled="loading">
                <i class="fa fa-trash-o"></i>
              </button>
              <button type="button" class="btn btn-sm btn-secondary" @click="selectTag($event, tag)" :disabled="loading">
                <i class="fa fa-angle-right"></i>
              </button>
            </div>
          </a>
          <div class="list-group-item">
            <a class="card-link" @click="addTag">{{ t.add }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card" v-if="selectedTag">
        <div class="card-header">
          {{ selectedTag.name }}
        </div>
        <div class="list-group list-group-flush">
          <a v-for="tag in subTags" :key="tag.id" class="list-group-item list-group-item-action cursor-pointer with-sub-buttons"
              :class="{active: tag.id === currentRecord.id && showModal}" @click="edit(tag, true)">
            {{ tag.name }}
            <div class="list-group-item-buttons">
              <button type="button" class="btn btn-sm btn-danger" @click="destroy($event, tag)" :disabled="loading">
                <i class="fa fa-trash-o"></i>
              </button>
            </div>
          </a>
          <div class="list-group-item">
            <a class="card-link" @click="addSubTag">{{ t.add }}</a>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showModal"
             :name="name"
             :cancel-title="t.cancel"
             :ok-title="t.save"
             :cancel-disabled="loading"
             :ok-disabled="loading"
             @hide="preventWhileLoading"
             @ok="save">
      <template v-slot:default>
        <form @submit="save">
          <div class="form-group" style="display: none;">
            <label>{{ t.type }}</label>
            <input class="form-control" v-model="currentRecord.type"
                   :class="{'is-invalid': invalid.type}"
                   :disabled="loading"/>
            <small class="invalid-feedback" v-for="message in invalid.type">{{
                message
              }}</small>
          </div>

          <div class="form-group">
            <label>{{ t.name }}</label>
            <input class="form-control" v-model="currentRecord.name"
                   :class="{'is-invalid': invalid.name}"
                   :disabled="loading"/>
            <small class="invalid-feedback" v-for="message in invalid.name">{{
                message
              }}</small>
          </div>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Axios from 'axios';
import {BModal} from 'bootstrap-vue';
import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';

const CancelToken = Axios.CancelToken;

export default {
  components: {BModal},
  computed: {
    mainTags() {
      return this.tags.filter(e => !e.parent_id);
    },
    subTags() {
      return this.tags.filter(e => e.parent_id === this.selectedTag.id);
    },
  },
  data() {
    return {
      name: null,
      initialLoading: true,
      source: null,
      loading: true,
      indexUrl: null,
      updateUrl: null,
      tags: [],
      selectedTag: null,
      showModal: false,
      invalid: {},
      currentRecord: {},
      t: {},
    }
  },
  methods: {
    showTags(res) {
      this.initialLoading = false;
      this.loading = false;
      this.tags = res.data;
    },
    handleError(err) {
      this.initialLoading = false;
      this.loading = false;
      if (Axios.isCancel(err)) {
        return;
      }
    },
    addTag() {
      if (this.loading) {
        return;
      }
      this.invalid = {};
      this.currentRecord = {type: 'Contents'};
      this.showModal = true;
      this.t.name = this.t.mainTag + "名";
    },
    addSubTag() {
      if (this.loading) {
        return;
      }
      this.invalid = {};
      this.currentRecord = {parent_id: this.selectedTag.id, type: 'Contents'};
      this.showModal = true;
      this.t.name = this.selectedTag.name + this.t.mainTag + "名";
    },
    save(event) {
      if (this.loading) {
        return;
      }
      this.invalid = {};
      this.loading = true;
      event.preventDefault();

      const url = this.currentRecord.id == null ? this.indexUrl : this.updateUrl.replace(/:id/, this.currentRecord.id);
      const method = this.currentRecord.id == null ? 'post' : 'patch';
      this.source = CancelToken.source();
      Axios.request({
        url: url,
        method: method,
        data: this.currentRecord,
        cancelToken: this.source.token
      })
      .then(this.saveFinished).catch(this.handleSaveError);
    },
    handleSaveError(err) {
      this.loading = false;
      if (err.response.data && err.response.data.messages) {
        this.invalid = err.response.data.messages;
        if (this.invalid.unique_key) {
          this.invalid.code = this.invalid.code || [];
          this.invalid.code = this.invalid.code.concat(this.invalid.unique_key);
        } else {
          if(!alert(err.response.data.messages)){window.location.reload();}
        }
      } else {
        alert(this.t.unknownError);
      }
    },
    saveFinished(res) {
      this.loading = false;
      this.showModal = false;
      const record = res.data;
      const index = findIndex(this.tags, e => e.id === record.id);
      if (index === -1) {
        this.tags.push(res.data);
      } else {
        this.tags.splice(index, 1, record);
      }
    },
    preventWhileLoading(event) {
      if (this.loading) {
        event.preventDefault();
      }
    },
    selectTag(event, tag) {
      this.selectedTag = tag;
      event.stopImmediatePropagation();
    },
    edit(tag, isSub) {
      if (this.loading) {
        return;
      }
      this.invalid = {};
      this.currentRecord = Object.assign({}, tag);
      this.showModal = true;
      this.name = isSub ? this.t.editSubTag : this.t.editTag;
      this.t.name = isSub ? this.selectedTag.name + this.t.mainTag + "名" : this.t.mainTag + "名";
    },
    destroy(event, tag) {
      if (this.loading) {
        return;
      }
      event.stopImmediatePropagation();
      const url = this.updateUrl.replace(/:id/, tag.id);
      this.source = CancelToken.source();
      this.loading = true;
      Axios.delete(url, {cancelToken: this.source.token})
          .then(() => {
            this.loading = false;
            const index = findIndex(this.tags, e => e.id === tag.id);
            this.tags.splice(index, 1);
          })
          .catch(err => {
            this.loading = false;
            alert(err.response && err.response.data && err.response.data.messages && err.response.data.messages.join('\n') || this.t.unknownError)
          });
    },
  },
  beforeMount() {
    const dataset = this.$root.$el.dataset;
    this.indexUrl = dataset.indexUrl;
    this.updateUrl = dataset.updateUrl;
    this.t.type = dataset.tType;
    this.t.name = dataset.tName;
    this.t.parentId = dataset.tParentId;
    this.t.unknownError = dataset.tUnknownError;
    this.t.mainTag = dataset.tMainTag;
    this.t.add = dataset.tAdd;
    this.t.save = dataset.tSave;
    this.t.cancel = dataset.tCancel;
    this.t.newTag = dataset.tNewTag;
    this.t.newSubTag = dataset.tNewSubTag;
    this.t.editTag = dataset.tEditTag;
    this.t.editSubTag = dataset.tEditSubTag;
  },
  mounted() {
    this.source = CancelToken.source();
    Axios.get(this.indexUrl, {cancelToken: this.source.token})
        .then(this.showTags).catch(this.handleError);
  },
  beforeDestroy() {
    this.source && this.source.cancel();
  }
}
</script>

<style scoped>
.root {
  padding-top: 84px;
  padding-bottom: 4rem;
}

.list-group-item.with-buttons {
  padding-right: 148px;
}

.list-group-item.with-sub-buttons {
  padding-right: 112px;
}

.list-group-item-buttons {
  position: absolute;
  top: 9px;
  right: 5px;
}

.list-group-item-buttons .btn {
  width: 31px;
}

.card-link, .cursor-pointer {
  cursor: pointer;
}
</style>
