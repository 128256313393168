<template>
  <div class="row root">
    <div class="col">
      <div class="card">
        <div class="card-header">
          {{ t.mainCategory }}
        </div>
        <div class="list-group list-group-flush">
          <div v-if="initialLoading" class="list-group-item text-muted text-center">
            <i class="fa fa-circle-o-notch fa-spin fa-fw"></i>
          </div>
          <a v-for="category in mainCategories" :key="category.id" class="list-group-item list-group-item-action cursor-pointer with-buttons"
              :class="{active: category.id === currentRecord.id && showModal || selectedCategory && category.id === selectedCategory.id}" @click="edit(category)">
            <i class="fa" :class="`fa-${category.icon}`"></i>
            {{ category.label }}
            <div class="list-group-item-buttons">
              <button type="button" class="btn btn-sm btn-danger" @click="destroy($event, category)" :disabled="loading">
                <i class="fa fa-trash-o"></i>
              </button>
              <button type="button" class="btn btn-sm btn-secondary" @click="moveUp($event, category)" :disabled="loading">
                <i class="fa fa-angle-up"></i>
              </button>
              <button type="button" class="btn btn-sm btn-secondary" @click="moveDown($event, category)" :disabled="loading">
                <i class="fa fa-angle-down"></i>
              </button>
              <button type="button" class="btn btn-sm btn-secondary" @click="selectCategory($event, category)" :disabled="loading">
                <i class="fa fa-angle-right"></i>
              </button>
            </div>
          </a>
          <div class="list-group-item">
            <a class="card-link" @click="addCategory">{{ t.add }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card" v-if="selectedCategory">
        <div class="card-header">
          {{ selectedCategory.label }}
        </div>
        <div class="list-group list-group-flush">
          <a v-for="category in subCategories" :key="category.id" class="list-group-item list-group-item-action cursor-pointer with-sub-buttons"
              :class="{active: category.id === currentRecord.id && showModal}" @click="edit(category, true)">
            {{ category.label }}
            <div class="list-group-item-buttons">
              <button type="button" class="btn btn-sm btn-danger" @click="destroy($event, category)" :disabled="loading">
                <i class="fa fa-trash-o"></i>
              </button>
              <button type="button" class="btn btn-sm btn-secondary" @click="moveUp($event, category)" :disabled="loading">
                <i class="fa fa-angle-up"></i>
              </button>
              <button type="button" class="btn btn-sm btn-secondary" @click="moveDown($event, category)" :disabled="loading">
                <i class="fa fa-angle-down"></i>
              </button>
            </div>
          </a>
          <div class="list-group-item">
            <a class="card-link" @click="addSubCategory">{{ t.add }}</a>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showModal"
             :title="title"
             :cancel-title="t.cancel"
             :ok-title="t.save"
             :cancel-disabled="loading"
             :ok-disabled="loading"
             @hide="preventWhileLoading"
             @ok="save">
      <template v-slot:default>
        <form @submit="save">
          <div class="form-group">
            <label>{{ t.code }}</label>
            <input class="form-control" v-model="currentRecord.code"
                   :class="{'is-invalid': invalid.code}"
                   :disabled="loading"/>
            <small class="invalid-feedback" v-for="message in invalid.code">{{
                message
              }}</small>
          </div>
          <div class="form-group">
            <label>{{ t.label }}</label>
            <input class="form-control" v-model="currentRecord.label"
                   :class="{'is-invalid': invalid.label}"
                   :disabled="loading"/>
            <small class="invalid-feedback" v-for="message in invalid.label">
              {{ message }}
            </small>
          </div>
          <div class="form-group" v-if="currentRecord.parent_id == null">
            <label>{{ t.icon }}</label>
            <input class="form-control" v-model="currentRecord.icon"
                   :class="{'is-invalid': invalid.icon}"
                   :disabled="loading"/>
            <small class="invalid-feedback" v-for="message in invalid.icon">
              {{ message }}
            </small>
            <small class="form-text">
              <a href="https://fontawesome.com/v4.7.0/icons/">https://fontawesome.com/v4.7.0/icons/</a>
            </small>
          </div>
          <div class="form-group">
            <label>{{ t.description }}</label>
            <textarea class="form-control" v-model="currentRecord.description"
                      :class="{'is-invalid': invalid.description}"
                      rows="4" :disabled="loading"></textarea>
            <small class="invalid-feedback" v-for="message in invalid.description">
              {{ message }}
            </small>
          </div>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Axios from 'axios';
import {BModal} from 'bootstrap-vue';
import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';

const CancelToken = Axios.CancelToken;

export default {
  components: {BModal},
  computed: {
    mainCategories() {
      return this.categories.filter(e => !e.parent_id);
    },
    subCategories() {
      return this.categories.filter(e => e.parent_id === this.selectedCategory.id);
    },
  },
  data() {
    return {
      title: null,
      initialLoading: true,
      source: null,
      loading: true,
      indexUrl: null,
      updateUrl: null,
      categories: [],
      selectedCategory: null,
      showModal: false,
      invalid: {},
      currentRecord: {},
      t: {},
    }
  },
  methods: {
    showCategories(res) {
      this.initialLoading = false;
      this.loading = false;
      this.categories = res.data;
    },
    handleError(err) {
      this.initialLoading = false;
      this.loading = false;
      if (Axios.isCancel(err)) {
        return;
      }
    },
    addCategory() {
      if (this.loading) {
        return;
      }
      this.invalid = {};
      this.currentRecord = {};
      this.showModal = true;
      this.title = this.t.newCategory;
    },
    addSubCategory() {
      if (this.loading) {
        return;
      }
      this.invalid = {};
      this.currentRecord = {parent_id: this.selectedCategory.id};
      this.showModal = true;
      this.title = this.t.newSubCategory;
    },
    save(event) {
      if (this.loading) {
        return;
      }
      this.invalid = {};
      this.loading = true;
      event.preventDefault();

      const url = this.currentRecord.id == null ? this.indexUrl : this.updateUrl.replace(/:id/, this.currentRecord.id);
      const method = this.currentRecord.id == null ? 'post' : 'patch';
      this.source = CancelToken.source();
      Axios.request({
        url: url,
        method: method,
        data: this.currentRecord,
        cancelToken: this.source.token
      })
          .then(this.saveFinished).catch(this.handleSaveError);
    },
    handleSaveError(err) {
      this.loading = false;
      if (err.response.data && err.response.data.messages) {
        this.invalid = err.response.data.messages;
        if (this.invalid.unique_key) {
          this.invalid.code = this.invalid.code || [];
          this.invalid.code = this.invalid.code.concat(this.invalid.unique_key);
        }
      } else {
        alert(this.t.unknownError);
      }
    },
    saveFinished(res) {
      this.loading = false;
      this.showModal = false;
      const record = res.data;
      const index = findIndex(this.categories, e => e.id === record.id);
      if (index === -1) {
        this.categories.push(res.data);
      } else {
        this.categories.splice(index, 1, record);
      }
    },
    preventWhileLoading(event) {
      if (this.loading) {
        event.preventDefault();
      }
    },
    selectCategory(event, category) {
      this.selectedCategory = category;
      event.stopImmediatePropagation();
    },
    moveUp(event, category) {
      event.stopImmediatePropagation();
      this.move(category, this.upUrl);
    },
    moveDown(event, category) {
      event.stopImmediatePropagation();
      this.move(category, this.downUrl);
    },
    move(category, url) {
      if (this.loading) {
        return;
      }
      url = url.replace(/:id/, category.id);
      this.source = CancelToken.source();
      this.loading = true;
      Axios.post(url, this.currentRecord, {cancelToken: this.source.token})
          .then(res => {
            this.loading = false;
            this.categories = this.categories.filter(e => e.parent_id !== category.parent_id);
            this.categories = this.categories.concat(res.data);
          })
          .catch(() => {
            this.loading = false;
            alert(this.t.unknownError);
          });
    },
    edit(category, isSub) {
      if (this.loading) {
        return;
      }
      this.invalid = {};
      this.currentRecord = Object.assign({}, category);
      this.showModal = true;
      this.title = isSub ? this.t.editSubCategory : this.t.editCategory;
    },
    destroy(event, category) {
      if (this.loading) {
        return;
      }
      event.stopImmediatePropagation();
      const url = this.updateUrl.replace(/:id/, category.id);
      this.source = CancelToken.source();
      this.loading = true;
      Axios.delete(url, {cancelToken: this.source.token})
          .then(() => {
            this.loading = false;
            const index = findIndex(this.categories, e => e.id === category.id);
            this.categories.splice(index, 1);
          })
          .catch(err => {
            this.loading = false;
            alert(err.response && err.response.data && err.response.data.messages && err.response.data.messages.join('\n') || this.t.unknownError)
          });
    },
  },
  beforeMount() {
    const dataset = this.$root.$el.dataset;
    this.indexUrl = dataset.indexUrl;
    this.updateUrl = dataset.updateUrl;
    this.upUrl = dataset.upUrl;
    this.downUrl = dataset.downUrl;
    this.t.code = dataset.tCode;
    this.t.label = dataset.tLabel;
    this.t.icon = dataset.tIcon;
    this.t.description = dataset.tDescription;
    this.t.unknownError = dataset.tUnknownError;
    this.t.mainCategory = dataset.tMainCategory;
    this.t.add = dataset.tAdd;
    this.t.save = dataset.tSave;
    this.t.cancel = dataset.tCancel;
    this.t.newCategory = dataset.tNewCategory;
    this.t.newSubCategory = dataset.tNewSubCategory;
    this.t.editCategory = dataset.tEditCategory;
    this.t.editSubCategory = dataset.tEditSubCategory;
  },
  mounted() {
    this.source = CancelToken.source();
    Axios.get(this.indexUrl, {cancelToken: this.source.token})
        .then(this.showCategories).catch(this.handleError);
  },
  beforeDestroy() {
    this.source && this.source.cancel();
  }
}
</script>

<style scoped>
.root {
  padding-top: 84px;
  padding-bottom: 4rem;
}

.list-group-item.with-buttons {
  padding-right: 148px;
}

.list-group-item.with-sub-buttons {
  padding-right: 112px;
}

.list-group-item-buttons {
  position: absolute;
  top: 9px;
  right: 5px;
}

.list-group-item-buttons .btn {
  width: 31px;
}

.card-link, .cursor-pointer {
  cursor: pointer;
}
</style>
