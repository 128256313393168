import Trix from 'trix';

Trix.config.blockAttributes.center = {tagName: 'center', nestable: false};

const BUTTON_HTML = `
    <button type="button" class="trix-button trix-button-center trix-button-fa-icon" data-trix-attribute="center" title="Center" tabIndex="-1">
        <i class="fa fa-align-center"></i>
    </button>
`.trim();

addEventListener('trix-initialize', event => {
    const editorElement = event.target;
    const $toolbar = $(editorElement.toolbarElement);

    if ($toolbar.find('[data-trix-attribute="center"]').length > 0) {
        return;
    }
    $toolbar.find('.trix-button-group--block-tools').append(BUTTON_HTML);
});
