<!-- millca (arsoa-suite) と mocopa (arsoa-mocopa) で共通 -->

<template>
  <div class="vfc-popover-container">
    <input type="text"
           class="form-control timepicker"
           autocomplete="off"
           :name="name"
           :id="inputId"
           :class="{'is-invalid': invalid}"
           :placeholder="placeholder"
           :required="required"
           :value="value"
           @keydown="handleKeydown"
           @focus="openDropdown"
           @click="openDropdown"
           @blur="handleBlur"
           @input="handleInput"
           @change="handleInputApply"
           ref="input"/>
    <div class="dropdown" @mousedown="keepFocus">
      <div class="dropdown-menu" :class="{show: showDropdown, 'dropdown-menu-right': alignRight}" ref="dropdownMenu">
        <button v-for="(time, index) in times" type="button" class="dropdown-item"
                tabindex="-1" :value="time" :class="{active: index === activeIndex}"
                ref="timeButtons" @click="handleDropdownClick">
          {{ time }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const KEY = {
  ENTER: 13,
  ESC: 27,
  ARROW_UP: 38,
  ARROW_DOWN: 40
};

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    inputId: {
      type: String,
      default: ''
    },
    invalid: {
      type: Boolean,
      default: false
    },
    times: {
      type: Array,
      default: () => new Array(24).fill(0).map((e, i) => `${String(i).padStart(2, '0')}:00`)
    },
    required: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: '',
      activeIndex: -1,
      showDropdown: false,
      alignRight: false,
      cancelBlue: false
    }
  },
  methods: {
    handleDropdownClick(event) {
      this.handleInputApply(event);
      this.closeDropdown();
      this.$emit('choseTime', this.value);
    },
    keepFocus(event) {
      this.cancelBlue = true;
    },
    handleKeydown(event) {
      let preventDefault = true;
      if (event.keyCode === KEY.ARROW_DOWN) {
        if (this.showDropdown) {
          this.moveActiveIndex(Math.min(this.activeIndex + 1, this.times.length - 1));
          this.handleInputApply(this.wrapEvent(this.times[this.activeIndex]));
        } else {
          this.openDropdown();
        }
      } else if (event.keyCode === KEY.ARROW_UP) {
        if (this.showDropdown) {
          this.moveActiveIndex(Math.max(this.activeIndex - 1, 0));
          this.handleInputApply(this.wrapEvent(this.times[this.activeIndex]));
        } else {
          this.openDropdown();
        }
      } else if (event.keyCode === KEY.ENTER && this.showDropdown && this.activeIndex >= 0) {
        this.closeDropdown();
        if (!this.normalizeTime(this.value, true)) {
          this.handleInputApply(this.wrapEvent(this.times[this.activeIndex]));
        }
      } else if (event.keyCode === KEY.ESC && this.showDropdown) {
        this.closeDropdown();
      } else {
        preventDefault = false;
      }
      preventDefault && event.preventDefault();
    },
    openDropdown() {
      if (this.showDropdown) {
        return;
      }
      this.showDropdown = true;
      this.alignRight = false;
      this.$nextTick(() => {
        this.moveActiveIndex(this.activeIndex);

        const dropdownRect = this.$refs.dropdownMenu.getBoundingClientRect();
        const windowLeft = window.pageXOffset + document.documentElement.clientWidth;
        this.alignRight = dropdownRect.right > windowLeft;
      });
    },
    closeDropdown() {
      this.showDropdown = false;
      this.cancelBlue = false;
    },
    handleBlur(event) {
      if (this.cancelBlue) {
        this.cancelBlue = false;
        this.$refs.input.focus();
      } else {
        this.closeDropdown();
      }
    },
    handleInput(event) {
      this.value = this.normalizeTime(event.target.value);
      this.findActive(this.value);
    },
    handleInputApply(event) {
      this.value = this.normalizeTime(event.target.value, true);
      this.findActive(this.value);
      this.$emit('choseTime', this.value);
    },
    wrapEvent(value) {
      return {target: {value}};
    },
    normalizeTime(value, strict) {
      value = value || '';
      value = value.trim();
      if (value.match(/^\d:$/)) {
        value = `0${value}`;
      }
      if (value.match(/^\d\d\d/)) {
        value = `${value.slice(0, 2)}:${value.slice(2)}`;
      }
      if (strict) {
        return value.match(/^(?:[01]?[0-9]|2[0-3]):[0-5][0-9]$/) ? value : '';
      }
      return value;
    },
    findActive(value, len = 5) {
      if (len <= 0) {
        return;
      }
      if (len >= 5) {
        value = this.normalizeTime(value);
      }
      value = value.slice(0, len);
      const activeIndex = this.times.findIndex(e => e.slice(0, len) === value);
      if (activeIndex >= 0) {
        this.moveActiveIndex(activeIndex);
      } else {
        this.findActive(value, len - 1);
      }
    },
    moveActiveIndex(index) {
      if (index < 0 || index >= this.times.length) {
        return;
      }
      this.activeIndex = index;
      if (!this.$refs.timeButtons) {
        return
      }
      const $button = this.$refs.timeButtons[index];
      const $dropdown = $button.parentElement;
      const dropdownRect = $dropdown.getBoundingClientRect();
      const buttonRect = $button.getBoundingClientRect();
      if (dropdownRect.top > buttonRect.top) {
        $dropdown.scrollTop -= (dropdownRect.height - buttonRect.height) / 2 + dropdownRect.top - buttonRect.top;
      } else if (dropdownRect.bottom < buttonRect.bottom) {
        $dropdown.scrollTop += (dropdownRect.height - buttonRect.height) / 2 + buttonRect.bottom - dropdownRect.bottom;
      }
    }
  },
  created() {
    this.value = this.$props.initialValue;
    this.findActive(this.value);
  }
}
</script>

<style scoped>
.dropdown-menu {
  z-index: 999;
  max-height: 16rem;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
