// コンテンツ中画像の拡大表示

export default function () {
  if($('#content').length == 0) { return; }

  $('#content action-text-attachment img').on('click', (evt) => {
    let image = evt.target;
    $('#image-modal #image-modal-content img')[0].src = image.src;
    $('#image-modal').show();
    $(document.body).addClass('modal-open'); // bodyのスクロール抑止
  });

  $('#image-modal .close').on('click', () => {
    $('#image-modal').hide();
    $(document.body).removeClass('modal-open');
  });
}